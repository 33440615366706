@font-face {
  font-family: "N27";
  src: url(./assets/fonts/N27-Regular.otf);
}

@font-face {
  font-family: "SquaresFunction-Normal";
  src: url(./assets/fonts/SquaresFunction-Normal.ttf);
}

@font-face {
  font-family: "SquaresFunction-Outline";
  src: url(./assets/fonts/SquaresFunction-Outline.ttf);
}
